<template>
    <div v-if="OpenerCards.length !== 0" class="MultiplePushBobbinOpener absolute allw allh">
        <div v-for="(item,index) in OpenerName" :key="index" class="flex">
            <div class="CardName flex items-center content-center">
                <img :class="[LangeuageAgent === 'CH' ? '' : 'NeedSetWidth']"
                     :src="'./img/PushBobbin/'+item  + (LangeuageAgent === 'CH' ? '' : LangeuageAgent) + '.png'">
            </div>
            <div class="flex1 flex column CardsBox">
                <div class="flex1 relative flex content-center items-center">
                    <img v-for="(l,i) in OpenerCards[index+1]" v-show="l!==0" :key="i"
                         :src="'./img/mahjong/'+l+'.png'">
                    <div :class="{'BGH':index === OpenerPosition}"
                         class="CurrentResultBox absolute allw font24 colorfff flex items-center content-center">
                        {{OpenerCattleType[index]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'MultiplePushBobbinOpener',
        props: ['TableInfo'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                OpenerName: ['ttz_kp_t1', 'ttz_kp_t2', 'ttz_kp_t3', 'ttz_kp_t4', 'ttz_kp_t5'],
                OpenerPosition: '',  //开牌定位
                OpenerCattleType: ['', '', '', '', ''],
                OpenerCards: []
            }
        },
        created() {
            this.$nextTick(() => {
                this.Init(this.TableInfo, this.TableInfo);
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    this.Init(n, o);
                }, deep: true
            }
        },
        methods: {
            Init(n, o) {
                if (!n.cards) {
                    this.OpenerCards = [];
                    this.OpenerCattleType = ['', '', '', '', ''];
                    return;
                }
                this.OpenerCards = this.Clone(n.cards);
                let [a, b, c, d, e, f] = this.OpenerCards;
                let _a = [];
                let _b = [];
                let _c = [];
                let _d = [];
                let _e = [];
                let _f = [];
                let ToStringify = (obj) => {
                    return JSON.stringify(obj);
                };
                if (o.cards) [_a, _b, _c, _d, _e] = o.cards;
                if (a[0]) { //定位牌
                    // let serial = {
                    //   '0': '由庄家开始发牌',
                    //   '1': '由闲一开始发牌',
                    //   '2': '由闲二开始发牌',
                    //   '3': '由闲三开始发牌'
                    // };
                    let result = a[0] % 10 % 5;
                    this.OpenerPosition = result; //计算定位牌
                    // if (ToStringify(a) !== ToStringify(_a)) this.SetAudio(serial[result]);
                }
                b = b.filter(item => item !== 0);
                c = c.filter(item => item !== 0);
                d = d.filter(item => item !== 0);
                e = e.filter(item => item !== 0);
                f = f.filter(item => item !== 0);
                if (b.length === 2) {//庄
                    this.OpenerCattleType[0] = this.CountPushBobbin(b);
                }
                if (c.length === 2) {//筒1
                    this.OpenerCattleType[1] = this.CountPushBobbin(c);
                }
                if (d.length === 2) {//筒2
                    this.OpenerCattleType[2] = this.CountPushBobbin(d);
                }
                if (e.length === 2) {//筒3
                    this.OpenerCattleType[3] = this.CountPushBobbin(e);
                }
                if (f.length === 2) {//筒4
                    this.OpenerCattleType[4] = this.CountPushBobbin(f);
                }
            },
            CountPushBobbin(_cards) { //计算推筒子点数
                let NumToUp = ['一', '二', '三', '四', '五', '六', '七', '八', '九'];
                let NeedTrans = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
                let [a, b] = _cards;
                if (a === b) {
                    if (this.LangeuageAgent === 'CH') {
                        if (a === 10) return '天对';
                        return '对' + NumToUp[Number(a - 1)];
                    } else {
                        if (a === 10) return '天对';
                        return 'Phải' + NeedTrans[Number(a - 1)];
                    }
                }
                if (a === 10) a = 0.5;
                if (b === 10) b = 0.5;
                return parseInt((a + b) % 10);
            }
        }
    }
</script>

<style lang="less">
    .MultiplePushBobbinOpener {
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        z-index: 10;

        > div {
            width: 33.33%;
            height: 50%;
            float: left;

            &:first-child {
                height: 100%;
            }

            .CardName {
                margin: 0 .15rem 0 .1rem;

                img {
                    height: .23rem;

                    &.NeedSetWidth {
                        width: .45rem;
                    }
                }
            }

            .CardsBox {
                img {
                    width: .35rem;
                    height: .47rem;
                    margin: 0 .025rem;
                }

                .CurrentResultBox {
                    height: .5rem;
                    background-color: rgba(62, 127, 233, .3);

                    &.BGH {
                        background-color: rgba(207, 22, 20, .3) !important;
                    }
                }
            }
        }
    }
</style>
