<template>
    <div class="MultipleLZPushBobbin allw flex column border-box pal10 par10 pointer">
        <div class="flex1 backgroundWhite lz flex relative">
            <PushBobbinRoad :RoadList="RoadList" :IsGameIn="true" :NeedCreated="true"
                            :DefaultCount="8"></PushBobbinRoad>
            <MultiplePushBobbinOpener :TableInfo="TableInfo"></MultiplePushBobbinOpener>
        </div>
    </div>
</template>

<script>
    import PushBobbinRoad from '../Canvas/PushBobbinRoad';
    import MultiplePushBobbinOpener from '../CardOpener/MultiplePushBobbinOpener';

    export default {
        name: 'MultipleLZPushBobbin',
        props: ['TableInfo'],
        components: {PushBobbinRoad, MultiplePushBobbinOpener},
        data() {
            return {
                CopyTableInfo: {},
                ResultNumber: [0, 0, 0, 0],
                Timer: null,
                Timer1: null,
                RoadList: [],
            }
        },
        destroyed() {
            clearTimeout(this.Timer);
            clearTimeout(this.Timer1);
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (JSON.stringify(n) === JSON.stringify(o)) return;  //防止其他卓更新 当前卓也更新
                    this.CopyTableInfo = this.Clone(n);
                    if (JSON.stringify(n) !== JSON.stringify(o) && n.change === true) {
                        this.RoadList = n.transForm;
                        // let dataarr = [0, 0, 0, 0, 0];
                        // let arr = this.Clone(n.transForm);
                        // let fn = (obj, index) => {
                        //     if (obj < 0) return;
                        //     dataarr[index] += 1;
                        //     dataarr[4] += 1;
                        // };
                        // if (Array.isArray(arr)) {
                        //     arr.forEach((item, index) => {
                        //         let [a, b, c, d, e] = item;
                        //         fn(b, 0);
                        //         fn(c, 1);
                        //         fn(d, 2);
                        //         fn(e, 3);
                        //     });
                        // }
                        // this.ResultNumber = this.Clone(dataarr);
                    }
                }, deep: true, immediate: true
            },
        },
        created() {
            this.$nextTick(() => {
            });
        },
        methods: {}
    }
</script>

<style lang="less">
    .MultipleLZPushBobbin {
        height: 100%;
        background: url("../../../public/img/GameCenter/list_bg.png") no-repeat top center/100% 100%;
        transition: .3s;
        padding: .03rem;
    }
</style>
